<template>
  <VContainer class="d-flex flex-column w-100">
    <VRow>
      <VCol
        cols="12"
        lg="12"
        md="12"
        xs="12"
      >
        <h1 class="my-6 heading-3 textPrimary--text">
          {{ data.title }}
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="12"
        lg="6"
        md="6"
        sm="6"
      >
        <div class="heading-6 mb-3 textPrimary--text">
          {{ data.subTitle }}
        </div>
        <div
          v-if="data.linkedImage && !!data.linkedImage.length"
          :class="{ 'd-flex': $vuetify.breakpoint.xs }"
        >
          <div
            v-for="(item, index) in data.linkedImage"
            :key="item.id"
            class="mb-4"
            :class="{ 'ml-2': $vuetify.breakpoint.xs && index === data.linkedImage.length - 1 }"
          >
            <a
              v-if="item.image"
              :href="item.link"
            >
              <img
                :src="item.image.url"
                :class="{ 'img-xs': $vuetify.breakpoint.xs }"
                alt=""
              >
            </a>
          </div>
        </div>
      </VCol>
      <VCol
        v-if="data.picture && data.picture.url"
        class="p-0 image-col"
        cols="12"
        lg="6"
        md="6"
        sm="6"
      >
        <img
          :src="data.picture.url"
          alt=""
          class="mw-100"
        >
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>

export default {
  name: 'TryOurApp',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}

</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.img-xs {
    max-width: 100%;
    max-height: 100%;
}

.image-col {
  text-align: center;
}
@media screen and (max-width: 600px) {
  .content {
    flex-direction: column-reverse;
  }
}

</style>
