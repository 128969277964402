<template>
  <SDirectionSelection
    :getArrivalPlace="getArrivalPlace"
    :getDeparturePlace="getDeparturePlace"
    :getIsArrivalPlaceFilled="!!getIsArrivalPlaceFilled"
    :getIsDeparturePlaceFilled="!!getIsDeparturePlaceFilled"
    :getIsOpsZoneShouldBePrefilled="getIsOpsZoneShouldBePrefilled"
    :prefilledOpsZoneId="prefilledOpsZoneId"
    @validateBookingForm="$emit('validateBookingForm')"
    @setRideType="data => $store.commit('booking/setRideType', data)"
    @populateOpsZone="data => $store.commit('opsZone/populateOpsZone', data)"
    @setDeparturePlace="data => $store.commit('booking/setDeparturePlace', data)"
    @setArrivalPlace="data => $store.commit('booking/setArrivalPlace', data)"
    @clearDeparturePlace="$store.commit('booking/clearDeparturePlace')"
    @clearArrivalPlace="$store.commit('booking/clearArrivalPlace')"
    @clearBookingForm="clearBookingForm"
    @trackGtmEventFromInput="onTrackEventFromInput"
    @trackGtmEventToInput="onTrackEventToInput"
    @trackGtmEventSelectDeparturePlace="onSelectDeparturePlace"
    @trackGtmEventSelectArrivalPlace="onSelectArrivalPlace"
  />
</template>

<script>
import appConfig from '@/appConfig';
import { mapGetters } from 'vuex';
import { SDirectionSelection } from '@slg/web-customer-shared';
import GTMixin from '@/mixins/GTMMixin';

export default {
  name: 'DirectionSelection',
  components: {
    SDirectionSelection,
  },
  mixins: [GTMixin],
  props: {
    prefilledOpsZoneId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getArrivalPlace: 'booking/getArrivalPlace',
      getDeparturePlace: 'booking/getDeparturePlace',
      getIsArrivalPlaceFilled: 'booking/isArrivalPlaceFilled',
      getIsDeparturePlaceFilled: 'booking/isDeparturePlaceFilled',
      isOpsZoneSelected: 'opsZone/isOpsZoneSelected',
    }),
    getIsOpsZoneShouldBePrefilled() {
      return appConfig.isOpsZoneShouldBePrefilled;
    },
  },
  methods: {
    setDeparturePlace(data) {
      this.$store.commit('booking/setDeparturePlace', data);
      this.$emit('clearBookingRequestError');
    },
    setArrivalPlace(data) {
      this.$store.commit('booking/setArrivalPlace', data);
      this.$emit('clearBookingRequestError');
    },
    clearBookingForm() {
      if (this.isOpsZoneSelected) {
        this.$store.commit('booking/clearDates');
        this.$store.commit('booking/clearOptions');
        this.$store.commit('opsZone/clearOpsZone');
      }
    },
    onTrackEventFromInput() {
      this.trackGtmEvent('From input click', 'Input', 'Click', 'Open dialog with map and poi list');
      this.trackGtmEvent('Pop-up with map and poi list opened', 'Pop-up', 'Opened', '');
    },
    onTrackEventToInput() {
      this.trackGtmEvent('To input click', 'Input', 'Click', 'Open dialog with map and poi list');
      this.trackGtmEvent('Pop-up with map and poi list opened', 'Pop-up', 'Opened', '');
    },
    onSelectDeparturePlace() {
      this.trackGtmEvent('Select Pick Up address button click', 'Button', 'Click', '');
    },
    onSelectArrivalPlace() {
      this.trackGtmEvent('Select Drop Off address button click', 'Button', 'Click', '');
    },
  },
}
</script>
