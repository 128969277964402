<template>
  <SFooter
    :menuItems="getStrapiCoreMenu(menuItems)"
    :isShownPrivacyPreferences="getIsShownPrivacyPreferences"
    mainFooterLogoLink="https://sales-lentz.lu/"
    @openCookieDialog="openCookieDialog"
  >
    <template #customLogo>
      <img
        :src="require('@/assets/img/sales-lentz-logo-white.svg')"
        alt="logo"
      >
    </template>
    <template #customSocial>
      <div />
    </template>
    <template #imgLeft>
      <img
        v-if="getStrapiCoreSettings.footerImgLeft"
        :src="getStrapiCoreSettings.footerImgLeft.url"
      >
    </template>
    <template #imgRight>
      <img
        v-if="getStrapiCoreSettings.footerImgRight"
        :src="getStrapiCoreSettings.footerImgRight.url"
      >
    </template>
    <template #rights>
      {{ getStrapiCoreSettings.footerRights }}
    </template>
    <template #bottomLeft>
      <VueMarkdown v-if="getStrapiCoreSettings.footerBottomLeft">
        {{ getStrapiCoreSettings.footerBottomLeft }}
      </VueMarkdown>
    </template>
    <template #bottomRight>
      <VueMarkdown v-if="getStrapiCoreSettings.footerBottomRight">
        {{ getStrapiCoreSettings.footerBottomRight }}
      </VueMarkdown>
    </template>
  </SFooter>
</template>

<script>
import { SFooter } from '@slg/web-customer-shared';
import appConfig from '@/appConfig';
import { mapGetters } from 'vuex';
import VueMarkdown from 'vue-markdown';

export default {
  name: 'Footer',
  components: {
    SFooter,
    VueMarkdown,
  },
  props: {
    isFooterFullyShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      isAuth: 'auth/getIsAuth',
      getStrapiCoreMenu: 'common/getStrapiCoreMenu',
      getStrapiCoreSettings: 'common/getStrapiCoreSettings',
    }),
    getIsShownPrivacyPreferences() {
      return appConfig.extendedCookieSupport;
    },
    menuItems() {
      return [
        {
          strapiText: '',
          strapiKey: 'homeTitle',
          isAnotherDomain: false,
          title: 'footer.home',
          path: this.generatePathFromRouter('Booking'),
        },
        {
          strapiText: '',
          strapiKey: 'FAQTitle',
          isAnotherDomain: false,
          title: 'footer.faq',
          path: this.generatePathFromRouter('FAQ'),
        },
        {
          strapiText: '',
          strapiKey: 'contactUsTitle',
          isAnotherDomain: false,
          title: 'footer.contactUs',
          path: this.generatePathFromRouter('ContactUs'),
        },
        {
          strapiText: '',
          isAnotherDomain: true,
          strapiKey: 'policyTitle',
          title: 'footer.privacyPolicy',
          path: { name: this.getPrivacyPolicyPage },
        },
        {
          strapiText: '',
          isAnotherDomain: false,
          strapiKey: 'termsTitle',
          title: 'footer.termsAndConditions',
          path: this.generatePathFromRouter('TermsAndConditions'),
        },
      ];
    },
    getPrivacyPolicyPage() {
      if (this.$route.params.lang === 'de') {
        return 'https://www.slg.lu/de/politique-de-confidentialite/';
      }

      if (this.$route.params.lang === 'fr') {
        return 'https://www.slg.lu/politique-de-confidentialite/';
      }

      return 'https://www.slg.lu/en/politique-de-confidentialite/';
    },
  },
  methods: {
    generatePathFromRouter(name = '') {
      const { defaultLanguage } = appConfig;
      const lang = this.$route.params.lang || defaultLanguage;
      return { name, params: { lang } }
    },
    openCookieDialog() {
      this.$store.commit('common/changeCookieDialogOpenState', true);
    },
  },
}
</script>
<style lang="scss" scoped>
.footer-additional {
  margin-top: -36px !important;
}
</style>
