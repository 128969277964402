export default {
  namespaced: true,
  state: {
    isGlobalLoading: false,
    isSessionExpiredDialogOpen: false,
    label: '',
    strapiCoreSettings: {},
    isShownCookieDialog: false,
  },
  mutations: {
    changeCookieDialogOpenState(state, isShown) {
      state.isShownCookieDialog = isShown;
    },
    setGlobalLoading(state, isGlobalLoading) {
      state.isGlobalLoading = isGlobalLoading;
    },
    setLabel(state, label) {
      state.label = label;
    },
    changeSessionExpiredDialogOpenState(state, dialogState) {
      state.isSessionExpiredDialogOpen = dialogState;
    },
    setStrapiCoreSettings(state, data) {
      state.strapiCoreSettings = data;
    },
  },
  actions: {
  },
  getters: {
    getIsShownCookieDialog: (state) => state.isShownCookieDialog,
    isGlobalLoading: (state) => state.isGlobalLoading,
    getLabel: (state) => state.label,
    getIsSessionExpiredDialogOpen: (state) => state.isSessionExpiredDialogOpen,
    getStrapiCoreSettings: (state) => state.strapiCoreSettings,
    getStrapiCoreMenu: (state) => (menuItems) => menuItems.map((menuItem) => {
      if (state.strapiCoreSettings.navLinks) {
        let strapiMenuItem = ''
        Object.keys(state.strapiCoreSettings.navLinks)
          .forEach((key) => {
            if (key === menuItem.strapiKey) {
              strapiMenuItem = state.strapiCoreSettings.navLinks[key]
            }
          })
        return {
          ...menuItem,
          strapiText: strapiMenuItem,
        }
      }
      return menuItem;
    }),
  },
}
