<template>
  <VContainer class="d-flex flex-column w-100">
    <VRow>
      <VCol
        cols="12"
        lg="12"
        md="12"
        xs="12"
      >
        <h1 class="my-6 heading-3">
          {{ data.title }}
        </h1>
      </VCol>
    </VRow>
    <VRow v-if="data.sectionItem && !!data.sectionItem.length">
      <VCol
        v-for="item in data.sectionItem"
        :key="item.id"
        class="col mb-4 col-12"
        :lg="threeColumns ? 4 : 3"
        :md="threeColumns ? 4 : 3"
        :sm="threeColumns ? 4 : 6"
        col="12"
      >
        <div
          v-if="item.picture"
          class="image-col"
        >
          <img
            :src="item.picture.url"
            alt="Beep-Beep-Location"
            border="0"
            class="mb-6"
          >
        </div>
        <VueMarkdown class="sub-title heading-6 mb-3 textPrimary--text">
          {{ item.subTitle }}
        </VueMarkdown>
        <VueMarkdown class="description body-2 textPrimary--text">
          {{ item.description }}
        </VueMarkdown>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import { OpenLinkNewTabMixin } from '@slg/web-customer-shared';

export default {
  name: 'AdditionalInfo',
  mixins: [OpenLinkNewTabMixin],
  components: {
    VueMarkdown,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    threeColumns: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.setTargetAttributeToLink(['sub-title', 'description']);
  },
}

</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.image-col {
  text-align: center;
}
</style>
