import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from './auth'
import opsZone from './opsZone'
import booking from './booking'
import common from './common'

Vue.use(Vuex)

const vuexSessionStorage = new VuexPersistence({
  key: 'vuex-session-storage',
  storage: window.sessionStorage,
  reducer: (state) => ({
    opsZone: state.opsZone,
    booking: state.booking,
  }),
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    opsZone,
    booking,
    common,
  },
  plugins: [vuexSessionStorage.plugin],
})
