export default {
  methods: {
    trackGtmEvent(event, category, action, label, value = 5000, noninteraction = false) {
      this.$gtm.trackEvent({
        event, // Event type [default = 'interaction'] (Optional)
        category,
        action,
        label,
        value,
        noninteraction, // Optional
      });
    },
  },
}
