var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VExpandTransition',[_c('VCard',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCookieEnabled && !_vm.isCookieDialogShown),expression:"!isCookieEnabled && !isCookieDialogShown"}],staticClass:"cookie-banner",attrs:{"color":"brandingPrimary","data-test":"cookieBanner","tile":""}},[_c('VCardText',{staticClass:"breakpoint-xl d-flex"},[_c('div',{staticClass:"d-flex w-100"},[_c('VRow',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('VCol',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[(_vm.bannerDescription)?_c('VueMarkdown',{staticClass:"body-1 description textPrimary--text"},[_vm._v(" "+_vm._s(_vm.bannerDescription)+" ")]):_vm._e()],1),_c('VCol',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[_c('div',{staticClass:"d-flex",class:{
                'justify-content-end': _vm.$vuetify.breakpoint.smAndUp,
                'justify-content-center': _vm.$vuetify.breakpoint.sm,
                'flex-column-reverse': _vm.$vuetify.breakpoint.xs
              }},[_c('VBtn',{directives:[{name:"blur",rawName:"v-blur"}],staticClass:"br-8",class:{
                  'mr-4': !_vm.$vuetify.breakpoint.xs,
                  'mt-3': _vm.$vuetify.breakpoint.xs
                },attrs:{"color":"white","outlined":""},on:{"click":function($event){return _vm.$emit('openCookieDialog')}}},[_c('span',{staticClass:"button"},[_vm._v(" "+_vm._s(_vm.manageBtnText)+" ")])]),_c('div',{staticClass:"d-flex",class:{
                  'justify-content-end': !_vm.$vuetify.breakpoint.xs,
                  'justify-content-between w-100': _vm.$vuetify.breakpoint.xs
                }},[_c('VBtn',{directives:[{name:"blur",rawName:"v-blur"}],staticClass:"br-8",class:{
                    'mr-4': !_vm.$vuetify.breakpoint.xs,
                    'deny-button': _vm.$vuetify.breakpoint.xs
                  },attrs:{"color":"white","outlined":""},on:{"click":_vm.deny}},[_c('span',{staticClass:"button"},[_vm._v(" "+_vm._s(_vm.$t('cookieManagement.denyAll'))+" ")])]),_c('VBtn',{directives:[{name:"blur",rawName:"v-blur"}],staticClass:"br-8",class:{
                    'mr-4': !_vm.$vuetify.breakpoint.xs,
                    'accept-button': _vm.$vuetify.breakpoint.xs
                  },attrs:{"color":"white","data-test":"cookieActionButton"},on:{"click":function($event){return _vm.$emit('acceptCookieConsent', true)}}},[_c('span',{staticClass:"button"},[_vm._v(" "+_vm._s(_vm.acceptBtnText)+" ")])])],1)],1)])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }