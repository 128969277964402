import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import VueBraintree from 'vue-braintree';
import VueTelInputVuetify from 'vue-tel-input-vuetify';
import {
  SRestrict, SBlur, SValidationComponents, SPhoneRestrict,
} from '@slg/web-customer-shared';
import { connectToGtm } from '@/utils/integrations';
import i18n from './plugins/i18n';
import router from './router/index';
import axiosInterceptorsSetup from './utils/axiosInterceptors';
import App from './App.vue';
import store from './store';
import styles from './style/main.scss';
import 'leaflet/dist/leaflet.css';

connectToGtm();

Vue.component('ValidationObserver', SValidationComponents.ValidationObserver);
Vue.component('ValidationProvider', SValidationComponents.ValidationProvider);

Vue.directive('restrict', SRestrict);
Vue.directive('blur', SBlur);
Vue.directive('phone-restrict', SPhoneRestrict);

Vue.use(VueBraintree);
Vue.use(VueTelInputVuetify, { vuetify });
Vue.config.productionTip = false;

axiosInterceptorsSetup();

const app = new Vue({
  vuetify,
  router,
  store,
  i18n,
  styles,
  render: (h) => h(App),
}).$mount('#app')

if (window.Cypress) {
  window.app = app;
}
