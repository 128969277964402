<template>
  <div class="w-100 h-100 d-flex justify-content-center">
    <SConfirmation
      :appConfig="getAppConfig"
      :orderId="getOrderId"
      :firstName="getFirstName"
      :departurePlace="getDeparturePlace"
      :arrivalPlace="getArrivalPlace"
      @toMainPage="toMainPage"
      @handleServerError="handleServerError"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SConfirmation,
  SGlobalErrorDialogMixin,
} from '@slg/web-customer-shared';
import appConfig from '@/appConfig';
import GTMixin from '@/mixins/GTMMixin';

export default {
  name: 'ConfirmationPage',
  components: {
    SConfirmation,
  },
  mixins: [SGlobalErrorDialogMixin, GTMixin],
  beforeRouteEnter(to, _from, next) {
    const hasOrderIdProperty = Object.prototype.hasOwnProperty.call(to.params, 'orderId');
    if (hasOrderIdProperty) {
      next();
    } else {
      // redirect to home page
      next('');
    }
  },
  computed: {
    ...mapGetters({
      getFirstName: 'auth/getFirstName',
      getDeparturePlace: 'booking/getDeparturePlace',
      getArrivalPlace: 'booking/getArrivalPlace',
    }),
    getOrderId() {
      return this.$route.params.orderId;
    },
    getAppConfig() {
      return appConfig;
    },
  },
  mounted() {
    this.trackGtmEvent(
      'Confirmation page',
      'Page',
      'Loaded',
      'Confirmation page is loaded',
    );
  },
  beforeDestroy() {
    this.$store.commit('booking/clearBookingState');
    this.$store.commit('opsZone/clearOpsZone');
  },
  methods: {
    toMainPage() {
      this.$router.push({ name: 'Booking' });
    },
    async handleServerError(e) {
      await this.setGlobalError(e);
    },
  },
};
</script>

<style scoped>

</style>
