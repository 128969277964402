<template>
  <VContainer class="d-flex flex-column w-100">
    <VRow>
      <VCol
        cols="12"
        lg="12"
        md="12"
        xs="12"
      >
        <h1 class="my-6 heading-3 textPrimary--text">
          {{ data.title }}
        </h1>
      </VCol>
    </VRow>
    <template v-if="data.sectionItem && !!data.sectionItem.length">
      <VRow
        v-for="(item, key) in data.sectionItem"
        :key="item.id"
        class="mb-3 align-items-center"
        :class="(key + 1) % 2 === 0 && 'flex-row-reverse'"
      >
        <VCol
          cols="12"
          lg="6"
          md="6"
          xs="12"
        >
          <VueMarkdown class="sub-title heading-6 mb-3 textPrimary--text">
            {{ item.subTitle }}
          </VueMarkdown>
          <VueMarkdown class="description body-2 textPrimary--text">
            {{ item.description }}
          </VueMarkdown>
        </VCol>
        <VCol
          class="p-0 image-col"
          cols="12"
          lg="6"
          md="6"
          xs="12"
        >
          <img
            v-if="item.picture"
            :src="item.picture.url"
            alt=""
            class="mw-100"
          >
        </VCol>
      </VRow>
    </template>
  </VContainer>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import { OpenLinkNewTabMixin } from '@slg/web-customer-shared';

export default {
  name: 'About',
  mixins: [OpenLinkNewTabMixin],
  components: {
    VueMarkdown,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.setTargetAttributeToLink(['sub-title', 'description']);
  },
}

</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.image-col {
  text-align: center;
}
</style>
