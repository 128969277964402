<template>
  <div>
    <div class="d-flex justify-content-center mx-6">
      <SVehicleSearchResultDialog
        :isAnotherDateSelectionDialogOpen="isAnotherDateSelectionDialogOpen"
        :isRoundTrip="isRoundTrip"
        :isNeededVehicleFound="isNeededVehicleFound"
        :isOutwardVehicleFound="isOutwardVehicleFound"
        :isReturnVehicleFound="isReturnVehicleFound"
        :outwardState="getOutwardState"
        :returnState="getReturnState"
        @closeDialog="toMainPage"
        @trackGtmEventNoVehiclesFound="onTrackEventNoVehiclesFound"
      />
      <SServerErrorDialog
        :isServerErrorDialogOpen="isServerErrorDialogOpen"
        @closeDialog="toMainPage"
        @trackGtmEventServerErrorOccurred="onTrackEventServerErrorOccurred"
      />
      <SOnBoardPaymentErrorDialog
        :isOnBoardPaymentAvailableDialogOpen="isOnBoardPaymentAvailableDialogOpen"
        @closeDialog="isOnBoardPaymentAvailableDialogOpen = false"
        @trackGtmEventPayButtonClick="onTrackEventPayButtonClick"
      />
      <SPaymentDialog
        :isPaymentDialogOpen="isPaymentDialogOpen"
        :paymentType="getPaymentType"
        :paymentInfo="paymentInfo"
        @closePaymentDialog="isPaymentDialogOpen = false"
        @onPaymentSuccess="onPaymentSuccess"
        @trackGtmEventPayButtonClick="onTrackEventPayButtonClick"
      />
      <ValidationObserver
        ref="checkout"
        v-slot="{ invalid }"
        tag="div"
        name="checkout"
        class="breakpoint-xl"
      >
        <VRow
          dense
          :justify="!isExtraSmall ? 'space-between' : 'center'"
        >
          <VCol
            v-if="isExtraSmall"
            cols="12"
            class="pb-6"
          >
            <SMobileBookingSummary
              :isTripDetailsOpen="isTripDetailsOpen"
              :selectedRides="getSelectedRides"
              :getIsOpsZoneShouldBePrefilled="getIsOpsZoneShouldBePrefilled"
              @collapseTripDetails="isTripDetailsOpen = !isTripDetailsOpen"
            />
          </VCol>
          <VCol
            cols="12"
            lg="7"
            md="8"
            sm="6"
          >
            <PassengerInfo @onSignInSuccess="onSignInSuccess" />
          </VCol>
          <VCol
            cols="12"
            lg="3"
            md="4"
            sm="6"
          >
            <SCheckoutBookingSummary
              :isExtraSmall="isExtraSmall"
              :selectedRides="getSelectedRides"
              :getIsOpsZoneShouldBePrefilled="getIsOpsZoneShouldBePrefilled"
              :isAuth="!!getAuthToken"
              :invalid="invalid"
              termsAndConditionsPageName="terms-and-conditions"
              privacyPolicyPageName="https://www.slg.lu/en/politique-de-confidentialite/"
              :isCheckoutPage="isCheckoutPage"
              @updateAgreement="isAgree = !isAgree"
              @bookNow="bookNow"
            />
          </VCol>
        </VRow>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import appConfig from '@/appConfig';
import {
  SPaymentTypeEnum, STripTypeEnum, SCheckoutErrorsEnum, SGlobalErrorDialogMixin, SOnBoardPaymentErrorDialog, SOrderService,
  SMobileBookingSummary, SCheckoutBookingSummary, SVehicleSearchResultDialog, SPaymentDialog, SServerErrorDialog, SUserService,
} from '@slg/web-customer-shared';
import GTMixin from '@/mixins/GTMMixin';
import PassengerInfo from '@/components/checkout/passengerInfo/PassengerInfo.vue';
import { resolveReservationError } from './checkout.logic';

export default {
  name: 'Checkout',
  components: {
    SMobileBookingSummary,
    PassengerInfo,
    SCheckoutBookingSummary,
    SVehicleSearchResultDialog,
    SPaymentDialog,
    SOnBoardPaymentErrorDialog,
    SServerErrorDialog,
  },
  mixins: [
    SGlobalErrorDialogMixin,
    GTMixin,
  ],
  data() {
    return {
      isLoading: true,
      isAgree: false,
      isAnotherDateSelectionDialogOpen: false,
      isTripDetailsOpen: false,
      isNeededVehicleFound: null,
      isOutwardVehicleFound: null,
      isReturnVehicleFound: null,
      isPaymentDialogOpen: false,
      isOnBoardPaymentAvailableDialogOpen: false,
      isServerErrorDialogOpen: false,
      paymentInfo: null,
    }
  },
  computed: {
    ...mapGetters({
      getTripType: 'booking/getTripType',
      getAuthToken: 'auth/getAuthToken',
      isFreeTrip: 'opsZone/isFreeTrip',
      getSelectedRides: 'booking/getSelectedRides',
      getPaymentType: 'booking/getPaymentType',
      getPaymentMethod: 'booking/getPaymentMethod',
      getIsFreeTrip: 'booking/getIsFreeTrip',
      getBookingRequestId: 'booking/getBookingRequestId',
      getPassengerPhone: 'booking/getPassengerPhone',
      getPassengerFirstName: 'booking/getPassengerFirstName',
      getPassengerLastName: 'booking/getPassengerLastName',
      getPassengerEmail: 'booking/getPassengerEmail',
      getUserDetailsForBookingDetails: 'auth/getUserDetailsForBookingDetails',
      getCheckoutInfo: 'booking/getCheckoutInfo',
      getOutwardState: 'booking/getOutwardState',
      getReturnState: 'booking/getReturnState',
      getReservationId: 'booking/getReservationId',
      isAnotherPassenger: 'booking/isAnotherPassenger',
      getAnotherPassengerList: 'auth/getAnotherPassengerList',
    }),
    isRoundTrip() {
      return this.getTripType === STripTypeEnum.ROUND_TRIP;
    },
    isExtraSmall() {
      return this.$vuetify.breakpoint.xs;
    },
    isCheckoutPage() {
      return this.$route.name === 'Checkout';
    },
    getIsOpsZoneShouldBePrefilled() {
      return appConfig.isOpsZoneShouldBePrefilled;
    },
  },
  async created() {
    await this.onSignInSuccess();
  },
  methods: {
    onTrackEventNoVehiclesFound() {
      this.trackGtmEvent('The vehicle is not found dialog ok button click', 'Button', 'Click', 'Redirect to home page');
    },
    onTrackEventServerErrorOccurred() {
      this.trackGtmEvent('Server error occurred dialog ok button', 'Button', 'Click', 'Redirect to home page');
    },
    onTrackEventPayButtonClick() {
      this.trackGtmEvent('Pay button click', 'Button', 'Click', 'Confirm online payment');
    },
    async onPaymentSuccess(data) {
      this.$store.commit('booking/setIgnoreRemainingTime', true);
      this.isPaymentDialogOpen = false;
      const { orderId } = this.paymentInfo;

      const bookingCheckoutOrderModel = this.getPaymentType !== SPaymentTypeEnum.PRE_AUTHORIZATION
        ? {
          paymentMethodNonce: data.nonce,
          orderId,
        }
        : {
          paymentMethodId: this.getPaymentMethod,
          orderId,
        };

      this.$store.commit('common/setGlobalLoading', true);
      try {
        await SOrderService.bookingCheckoutOrder(bookingCheckoutOrderModel);
        this.trackGtmEvent(
          'Booking completed successfully',
          'Special event',
          '',
          'Booking completed successfully, redirect to confirmation page',
        );
        this.$store.commit('booking/clearTemporaryReservation');
        await this.$router.push({ name: 'Confirmation', params: { orderId } });
      } catch (e) {
        if (e.status !== 401) {
          await this.setGlobalError(e, false);
        }
      }
      this.$store.commit('common/setGlobalLoading', false);
      this.$store.commit('booking/setIgnoreRemainingTime', false);
    },
    createReservation(requestDelay, maxRequestDelay, spinnerText) {
      this.$store.commit('common/setLabel', spinnerText);
      setTimeout(async () => {
        await this.$store.dispatch('booking/setTemporaryReservation', this.getBookingRequestId)
          .then(() => {
            this.isNeededVehicleFound = true;
            this.isAnotherDateSelectionDialogOpen = true;
            setTimeout(() => {
              this.isAnotherDateSelectionDialogOpen = false
            }, 1000);
          })
          .catch(async (error) => {
            if (error.status >= 500) {
              this.isServerErrorDialogOpen = true;
            } else if (error.status !== 401) {
              const {
                isNeededVehicleFound,
                isOutwardVehicleFound,
                isReturnVehicleFound,
                isAnotherDateSelectionDialogOpen,
                isServerErrorDialogOpen,
                isWriteConflict,
              } = resolveReservationError(error, this.clearTemporaryReservationDeadline);
              this.isNeededVehicleFound = isNeededVehicleFound;
              this.isOutwardVehicleFound = isOutwardVehicleFound;
              this.isReturnVehicleFound = isReturnVehicleFound;
              this.isAnotherDateSelectionDialogOpen = isAnotherDateSelectionDialogOpen;
              this.isServerErrorDialogOpen = isServerErrorDialogOpen;
              requestDelay += 10000;

              if (isWriteConflict && requestDelay <= maxRequestDelay) {
                this.createReservation(
                  requestDelay,
                  maxRequestDelay,
                  requestDelay === 10000 ? 'checkout.spinnerText10SecondsDelay' : 'checkout.spinnerText20or30SecondsDelay',
                );
              } else if (isWriteConflict && requestDelay > maxRequestDelay) {
                this.setDefaultValuesToVehicleNotFoundDialog();
              }
            }
          });

        if (this.isNeededVehicleFound || this.isAnotherDateSelectionDialogOpen) {
          this.$store.commit('common/setGlobalLoading', false);
          this.$store.commit('common/setLabel', null);
        }
      }, requestDelay)
    },
    setDefaultValuesToVehicleNotFoundDialog() {
      this.isNeededVehicleFound = false;
      this.isOutwardVehicleFound = false;
      this.isReturnVehicleFound = false;
      this.isAnotherDateSelectionDialogOpen = true;
    },
    clearTemporaryReservationDeadline() {
      this.$store.commit('booking/clearTemporaryReservationDeadline');
    },
    fillDefaultPassengerDetails() {
      this.$store.commit('booking/setLoggedInPassengerDetails', this.getUserDetailsForBookingDetails);
    },
    toMainPage() {
      this.isAnotherDateSelectionDialogOpen = false;
      this.$router.push({ name: 'Booking' });
    },
    async saveAnotherPassenger() {
      await SUserService.updateProfile({
        anotherPassengerList: [
          {
            passengerId: uuidv4(),
            phone: this.getPassengerPhone,
            firstName: this.getPassengerFirstName.trim(),
            lastName: this.getPassengerLastName.trim(),
          },
        ],
      });
    },
    async bookNow() {
      if (this.getIsFreeTrip) {
        this.$store.commit('booking/setPaymentType', SPaymentTypeEnum.NO_PAYMENT);
      }

      this.trackGtmEvent('Book now button click', 'Button', 'Click', 'Booking confirmation');

      this.$refs.checkout.validate().then(async (success) => {
        if (!success) {
          return;
        }
        this.$nextTick(async () => {
          this.$store.commit('common/setGlobalLoading', true);
          try {
            if (this.isAnotherPassenger && !this.getAnotherPassengerList.length) {
              await this.saveAnotherPassenger();
            }

            const bookingOrderModel = {
              bookingRequestId: this.getBookingRequestId,
              flightNumber: this.getCheckoutInfo.FlightDetails,
              locale: this.$route.params.lang,
              outwardTripComment: this.getCheckoutInfo.commentForOutward,
              anotherPersonIsRiding: this.isAnotherPassenger,
              passengerDetails: [{
                phone: this.getPassengerPhone,
                firstName: this.getPassengerFirstName.trim(),
                lastName: this.getPassengerLastName.trim(),
                email: this.getPassengerEmail,
              }],
              paymentType: this.getPaymentType,
              returnTripComment: this.getCheckoutInfo.commentForReturn,
            }
            const now = new Date().valueOf();
            const { data } = await SOrderService.bookingOrderCreate(bookingOrderModel);
            this.paymentInfo = data;

            const refreshedRemainingTime = Math.round((data.reservedTill - now) / 1000);

            this.$store.commit('booking/setRemainingTime', refreshedRemainingTime);

            if (!this.getPaymentMethod
              && (this.getPaymentType === SPaymentTypeEnum.PRE_PAID || this.getPaymentType === SPaymentTypeEnum.PRE_AUTHORIZATION)) {
              this.isPaymentDialogOpen = true;
            } else if (this.getPaymentMethod
              && (this.getPaymentType === SPaymentTypeEnum.PRE_PAID || this.getPaymentType === SPaymentTypeEnum.PRE_AUTHORIZATION)) {
              await this.onPaymentSuccess({ nonce: '' });
            } else {
              await this.onPaymentSuccess({ nonce: '' });
            }
          } catch (e) {
            if (e.status !== 401) {
              if (e.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_PAYMENT_TYPE_NOT_ALLOWED) {
                this.isOnBoardPaymentAvailableDialogOpen = true;
              } else {
                await this.setGlobalError(e, true, { isErrorBookingConfirmation: true });
              }
            }
          }
          this.$store.commit('common/setGlobalLoading', false);
        });
      });
    },
    onSignInSuccess() {
      if (this.getAuthToken && !this.getReservationId) {
        this.fillDefaultPassengerDetails();
        this.$store.commit('common/setGlobalLoading', true);
        const requestDelay = 0;
        const maxRequestDelay = 30000;
        this.createReservation(requestDelay, maxRequestDelay, 'checkout.spinnerText');
      }
    },
  },
}
</script>
<style lang="scss" scoped>

.booking-summary-container {
  box-shadow: 0 4px 24px 0 #00000014;
}

.image-wrapper {
  img {
    width: 281px;
    height: 181px;
  }
}
</style>
