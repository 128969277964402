import Vue from 'vue';
import { VueGtm } from '@slg/web-customer-shared';
import router from '@/router/index';

const resolveGtmContainerId = () => window.config?.VUE_APP_GTM_CONTAINER_ID || process.env.VUE_APP_GTM_CONTAINER_ID || 'GTM-5TVFF6Q';

export const connectToGtm = () => {
  Vue.use(VueGtm, {
    id: resolveGtmContainerId(),
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: '',
      gtm_preview: '',
      gtm_cookies_win: 'x',
    },
    defer: false,
    compatibility: false,
    nonce: '2726c7f26c',
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: ['homepage'],
    trackOnNextTick: false,
  });
};
