<template>
  <div
    :key="pageKey"
    class="commune-page-container"
  >
    <SBackButton />
    <h1 class="heading-3 mb-2 mt-6">
      {{ title }}
    </h1>
    <div class="subtitle-3 textSecondary--text mb-6">
      {{ subTitle }}
    </div>
    <VueMarkdown
      v-if="description"
      class="body-2 mb-9"
    >
      {{ description }}
    </VueMarkdown>
    <VRow>
      <VCol>
        <VueMarkdown
          v-if="contactCommune"
          class="body-2"
        >
          {{ contactCommune }}
        </VueMarkdown>
      </VCol>
      <VCol>
        <VueMarkdown
          v-if="opening"
          class="body-2"
        >
          {{ opening }}
        </VueMarkdown>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { getMetaInfo } from '@/utils/seo';
import { getCommuneBySlug } from '@/services/cmsService';
import { SBackButton } from '@slg/web-customer-shared';
import VueMarkdown from 'vue-markdown';

export default {
  name: 'CommunePage',
  components: {
    SBackButton,
    VueMarkdown,
  },
  data() {
    return {
      title: '',
      description: '',
      pageKey: new Date().toString(),
      contactCommune: '',
      opening: '',
      seo: '',
      subTitle: '',
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.loadCmsData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async loadCmsData() {
      this.$store.commit('common/setGlobalLoading', true);
      try {
        const slug = this.$route.params.name;
        const data = await getCommuneBySlug(slug);
        this.seo = data?.Seo || '';
        this.title = data?.Title || '';
        this.subTitle = data?.Subtitle || '';
        this.description = data?.Description || '';
        this.opening = data?.Opening || '';
        this.contactCommune = data?.ContactCommune || '';
        this.pageKey = new Date().toString();
        this.seo = data?.Seo || '';
      } catch (error) {
        this.cmsData = null;
      } finally {
        this.$store.commit('common/setGlobalLoading', false);
      }
    },
  },
  metaInfo() {
    return getMetaInfo(this.seo);
  },
}
</script>

<style lang="scss">
.commune-page-container {
  margin: 0 10%;
  padding: 30px;
  padding-top: 80px;
  min-height: 400px;
}
</style>
