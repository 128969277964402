<template>
  <div class="position-absolute w-100 h-100 booking-img-container d-flex justify-content-center">
    <div class="position-relative w-100 h-100 img-container justify-content-center breakpoint-xl">
      <div
        class="position-absolute top-left-img-container"
        :style="`top: ${$vuetify.breakpoint.xs && '15px'}`"
      />
      <div
        class="position-absolute top-right-img-container"
        :style="`top: ${$vuetify.breakpoint.xs && '125px'}`"
      />
      <div
        class="position-absolute bottom-left-img-container"
        :style="`left: ${$vuetify.breakpoint.smAndUp ? '113px' : '0px'}`"
      >
        <img
          v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
          alt="Flexibus"
          src="@/assets/img/bus-driver-amico.svg"
        >
        <img
          v-else-if="$vuetify.breakpoint.md"
          alt="Flexibus"
          src="@/assets/img/bus-driver-amico-md.svg"
        >
        <img
          v-else-if="$vuetify.breakpoint.sm"
          alt="Flexibus"
          src="@/assets/img/bus-driver-amico-sm.svg"
        >
        <img
          v-else-if="$vuetify.breakpoint.xs"
          alt="Flexibus"
          src="@/assets/img/bus-driver-amico-xs.svg"
        >
      </div>
      <div class="position-absolute bottom-right-img-container" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookingBg',
}
</script>

<style scoped lang="scss">

.booking-img-container {
  top: 0;
}

.img-container {
  top: 0;
  bottom: 0;
  z-index: 0;

  .top-left-img-container {
    top: 45px;
    left: 15px;
  }

  .top-right-img-container {
    top: 62px;
    right: 20px;
  }

  .bottom-left-img-container {
    top: 110px;
    bottom: 0;
  }

  .bottom-right-img-container {
    bottom: 0;
    right: 0;
  }
}
</style>
