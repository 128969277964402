<template>
  <VContainer
    class="py-10"
    fluid
  >
    <VRow>
      <VCol
        class="pb-0"
        cols="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
      >
        <h1 class="heading-3 textPrimary--text">
          {{ title }}
        </h1>
      </VCol>
      <VCol
        class="pb-0 pt-6"
        cols="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
      >
        <VueMarkdown
          v-if="description"
          :key="descriptionKey"
          class="body-2 textPrimary--text"
        >
          {{ description }}
        </VueMarkdown>
      </VCol>
      <VCol
        class="pb-0 pt-3"
        cols="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
      >
        <span class="heading-6 textPrimary--text">
          {{ subTitle }}
        </span>
      </VCol>
    </VRow>
    <CommuneGroup
      class="pt-8 mt-0"
      :communesList="communesList"
    />
  </VContainer>
</template>

<script>
import { getMetaInfo } from '@/utils/seo';
import {
  SGlobalErrorDialogMixin,
  SUpdateCmsDataMixin,
} from '@slg/web-customer-shared';
import VueMarkdown from 'vue-markdown';
import CommuneGroup from '@/components/nightCard/CommuneGroup.vue';
import { getNightCardPage, getCommunes } from '@/services/cmsService';

export default {
  name: 'NightCardPage',
  components: {
    CommuneGroup,
    VueMarkdown,
  },
  mixins: [SGlobalErrorDialogMixin, SUpdateCmsDataMixin],
  data() {
    return {
      title: '',
      description: '',
      descriptionKey: new Date().toString(),
      subTitle: '',
      communesList: [],
      seo: '',
    };
  },
  metaInfo() {
    return getMetaInfo(this.seo);
  },
  methods: {
    async loadCmsData() {
      try {
        this.$store.commit('common/setGlobalLoading', true);
        await this.getNightCardPageAPI();
        await this.getNightCardListAPI();
      } catch (e) {
        // to-do rethink about catch
      }
      this.$store.commit('common/setGlobalLoading', false);
    },
    async getNightCardPageAPI() {
      const data = await getNightCardPage();
      this.seo = data.Seo;
      this.title = data.Title;
      this.subTitle = data.Subtitle;
      this.description = data.Description;
      this.descriptionKey = new Date().toString();
    },
    async getNightCardListAPI() {
      this.communesList = await getCommunes();
    },
  },
};
</script>

<style scoped>

</style>
